<template>
  <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
  >
    <!-- 错误提示 -->
    <a-alert v-if="isLoginError" type="error" showIcon :message="loginErrorMsg"/>
    <template v-if="!isSuccess">
      <a-form-item>
        <span style="padding-bottom: 5px">输入您账号的管理员姓名，方便企业找到您！</span>
        <a-input
            size="large"
            type="text"
            placeholder="姓名"
            v-decorator="[
            'real_name',
            {rules: [{ required: true, message: '您还输入没有姓名' }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <span style="padding-bottom: 5px">登录所需手机号码</span>
        <a-input
            size="large"
            type="text"
            placeholder="手机号码"
            v-decorator="[
            'mobile',
            {rules: [{ required: true, message: '您还没有输入手机号码' }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            size="large"
            type="text"
            placeholder="验证码"
            v-decorator="[
            'code',
            {rules: [{ required: true, message: '您还没有输入验证码' }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
          <a-button style="width: 95px" slot="addonAfter" @click="sendCode" :disabled="disabled">{{
              btn
            }}
          </a-button>
        </a-input>
      </a-form-item>
      <a-form-item>
        <span style="padding-bottom: 5px">设置登录密码</span>
        <a-input
            size="large"
            type="password"
            autocomplete="false"
            placeholder="登录密码"
            v-decorator="[
                      'password',
                      {rules: [{ required: true, message: '您还没有输入登录密码' }], validateTrigger: 'blur'}
                    ]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            size="large"
            autocomplete="false"
            placeholder="确认密码"
            type="password"
            v-decorator="['password_confirm', {rules: [
                  { message: '请输入确认密码'},
                  {validator: compareToFirstPassword}
                ]}]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>
      <div style="display: flex;justify-content: end">
        <a @click="setType(0)">返回登录</a>
      </div>

      <a-form-item style="margin-top:24px">
        <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
        >确认提交
        </a-button>
      </a-form-item>
    </template>
    <template v-else>
      <a-result
          status="success"
          title="提交成功!"
          sub-title="您已成为管理者，公司录用后方可进行登录进入系统！"
      >
        <template #extra>
          <a-button  @click="setType(0)" key="console" type="primary">
            返回登录页面
          </a-button>
        </template>
      </a-result>
    </template>
  </a-form>
</template>

<script>
import {mapActions} from 'vuex'
import {isEmpty} from '@/utils/util'
import * as Api from '@/api/login'

export default {
  data() {
    return {
      // 是否登录错误
      isLoginError: false,
      // 登录错误的信息
      loginErrorMsg: '登录失败',
      // 表单组件
      form: this.$form.createForm(this),
      // 页面状态
      state: {loginBtn: false},
      btn: '获取验证码',
      disabled: false,
      isSuccess: 0
    }
  },
  created() {
  },
  methods: {
    ...mapActions(['Login']),

    /**
     * 验证确认密码是否一致
     */
    compareToFirstPassword(rule, value, callback) {
      const {form} = this
      const passwordVal = form.getFieldValue('password')
      if (passwordVal && (value !== passwordVal)) {
        return new Error('您输入的确认密码不一致')
      }
      return true
    },


    setType(type) {
      this.$emit('setType', type)
    },
    async sendCode() {
      const mobile = this.form.getFieldValue('mobile')
      if (isEmpty(mobile)) {
        this.$message.error('请先输入手机号码')
        return
      }
      var s = 60
      const d = setInterval(() => {
        if (s < 0) {
          clearInterval(d)
          this.disabled = false
          this.btn = '获取验证码'
        } else {
          this.btn = s + 's'
          s--
        }
      }, 1000)
      this.disabled = true
      const {message} = await Api.sendCode({mobile, code_name: 'registerAdmin'})
      this.$message.success(message)
    },
    /**
     * 表单提交: 确定登录
     */
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: {validateFields},
        state,
        Login
      } = this
      state.loginBtn = true
      // 表单验证
      validateFields(['real_name', 'mobile', 'code', 'password', 'password_confirm'], {force: true}, (err, values) => {
        if (!err) {
          const loginParams = {...values, code_name: 'registerAdmin'}
          Api.registerAdmin(loginParams)
              .then(res => this.loginSuccess(res))
              .catch(err => this.loginFailed(err))
              .finally(() => {
                state.loginBtn = false
              })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 100)
        }
      })
    },

    /**
     * 登录成功
     */
    loginSuccess(res) {
      this.isLoginError = false
      // 显示提示信息
      this.$message.success(res.message, 1.5)
      this.isSuccess = 1
    },

    /**
     * 登录请求失败
     */
    loginFailed(response) {
      this.isLoginError = true
      this.loginErrorMsg = response.message
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 40px;
  text-align: center;

  .logo {
    height: 50px;
    vertical-align: top;
    border-style: none;
    margin-bottom: 20px;
  }

  .title {
    font-size: 20px;
    color: #6c7293;
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    position: relative;
    margin: 0;
  }
}

.user-layout-login {
  label {
    font-size: 14px;
  }

  /deep/ .login-input .ant-input {
    border: none;
    background: rgba(235, 237, 242, 0.4);
    border-radius: 5px;
    font-size: 14px;
  }

  /deep/ .ant-form-explain {
    margin-top: 5px;
    // color: rgba(245, 34, 45, 0.8);
    //color: rgba(0, 0, 0, 0.45);
  }

  .login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    box-shadow: 0px 4px 16px 0px rgba(34, 185, 255, 0.15);

    &:hover {
      box-shadow: 0px 9px 16px 0px rgba(34, 185, 255, 0.25) !important;
    }
  }

  .ant-alert-error {
    margin-bottom: 24px;
    background-color: #fffbfb;
  }
}
</style>

<template>
  <div class="main">
    <div class="header">
      <!--      <div class="logo">-->
      <!--        <img src="~@/assets/logo.svg" class="logo" alt="logo"/>-->
      <!--      </div>-->
      <p class="title">
        <span v-if="type===0">罐子科技物流管理系统</span>
        <span v-if="type===1">注册账号</span>
        <span v-if="type===2">重置密码</span>
        <span v-if="type===3">成为管理者</span>
      </p>
    </div>
    <template v-if="type===0">
      <LoginForm @setType="setType"/>
    </template>
    <template v-if="type===1">
      <RegisterForm @setType="setType"/>
    </template>
    <template v-if="type===2">
      <ResetPassword @setType="setType"/>
    </template>
    <template v-if="type===3">
      <RegisterAdmin @setType="setType"/>
    </template>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {isEmpty, timeFix} from '@/utils/util'
import * as Api from '@/api/login'
import LoginForm from "./model/Login";
import RegisterForm from "./model/Register";
import ResetPassword from "./model/ResetPassword";
import RegisterAdmin from "./model/RegisterAdmin";

export default {

  components: {
    LoginForm,
    RegisterForm,
    ResetPassword,
    RegisterAdmin
  },
  data() {
    return {
      type: 0,
      // 是否登录错误
      isLoginError: false,
      // 登录错误的信息
      loginErrorMsg: '登录失败',
      // 表单组件
      form: this.$form.createForm(this),
      // 页面状态
      state: {loginBtn: false},
      btn: '获取验证码',
      disabled: false,
    }
  },
  created() {
  },
  methods: {
    ...mapActions(['Login']),
    setType(type) {
      this.type = type
    },
    async sendCode() {
      const mobile = this.form.getFieldValue('mobile')
      if (isEmpty(mobile)) {
        this.$message.error('请先输入手机号码')
        return
      }
      var s = 60
      const d = setInterval(() => {
        if (s < 0) {
          clearInterval(d)
          this.disabled = false
          this.btn = '获取验证码'
        } else {
          this.btn = s + 's'
          s--
        }
      }, 1000)
      this.disabled = true
      const {message} = await Api.sendCode({mobile})
      this.$message.success(message)
    },
    /**
     * 表单提交: 确定登录
     */
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: {validateFields},
        state,
        Login
      } = this
      state.loginBtn = true
      // 表单验证
      validateFields(['mobile', 'code', 'password'], {force: true}, (err, values) => {
        if (!err) {
          const loginParams = {...values}
          Login(loginParams)
              .then(res => this.loginSuccess(res))
              .catch(err => this.loginFailed(err))
              .finally(() => {
                state.loginBtn = false
              })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 100)
        }
      })
    },

    /**
     * 登录成功
     */
    loginSuccess(res) {
      this.isLoginError = false
      // 显示提示信息
      this.$message.success(res.message, 1.5)
      setTimeout(() => {
        // 跳转到后台首页
        this.$router.push({path: '/'})
        // 显示欢迎信息
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
    },

    /**
     * 登录请求失败
     */
    loginFailed(response) {
      this.isLoginError = true
      this.loginErrorMsg = response.message
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 40px;
  text-align: center;

  .logo {
    height: 50px;
    vertical-align: top;
    border-style: none;
    margin-bottom: 20px;
  }

  .title {
    font-size: 20px;
    color: #6c7293;
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    position: relative;
    margin: 0;
  }
}
</style>
<script setup>
</script>

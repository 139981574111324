var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.isLoginError)?_c('a-alert',{attrs:{"type":"error","showIcon":"","message":_vm.loginErrorMsg}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mobile',
            {rules: [{ required: true, message: '您还没有输入手机号码' }], validateTrigger: 'change'}
          ]),expression:"[\n            'mobile',\n            {rules: [{ required: true, message: '您还没有输入手机号码' }], validateTrigger: 'change'}\n          ]"}],attrs:{"size":"large","type":"text","placeholder":"手机号码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mobile"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {rules: [{ required: true, message: '您还没有输入验证码' }], validateTrigger: 'change'}
          ]),expression:"[\n            'code',\n            {rules: [{ required: true, message: '您还没有输入验证码' }], validateTrigger: 'change'}\n          ]"}],attrs:{"size":"large","type":"text","placeholder":"验证码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"}),_c('a-button',{staticStyle:{"width":"95px"},attrs:{"slot":"addonAfter","disabled":_vm.disabled},on:{"click":_vm.sendCode},slot:"addonAfter"},[_vm._v(_vm._s(_vm.btn)+" ")])],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'password',
                      {rules: [{ required: true, message: '您还没有输入登录密码' }], validateTrigger: 'blur'}
                    ]),expression:"[\n                      'password',\n                      {rules: [{ required: true, message: '您还没有输入登录密码' }], validateTrigger: 'blur'}\n                    ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"请输入登录密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('a',{on:{"click":function($event){return _vm.setType(1)}}},[_vm._v("注册账号")]),_c('a',{on:{"click":function($event){return _vm.setType(2)}}},[_vm._v("忘记密码")])]),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("登录 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }